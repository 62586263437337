import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.css']
})
export class ConsultaComponent implements OnInit {
  title = 'CONSULTA CONTRATOS 16X7 Y 24X7';
  nombreusuario = this.userService.tusuario;
  cfor = this.userService.datos;

  dat: any = null;

  displayedColumns: string[] = ['FechaVtoContrato', 'NIF','CuentaNombre', 'Descripcion'];
  dataSource = new MatTableDataSource(this.cfor);

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(private userService: UserService, private router: Router, private http: HttpClient) {

    let token = localStorage.getItem('tokenUser');
    if (token === '' || token === null || typeof token === 'undefined') {
      this.router.navigate(['**']);
    }

    //this.userService.consultaDatos();

    let headersAux = new HttpHeaders();
    headersAux = headersAux.set('Content-Type', 'application/json; charset=utf-8');
    headersAux = headersAux.set('authtoken', token);

    this.http.get(userService.apiConsulta, { headers: headersAux })
    .subscribe(resp => {
      this.dat = resp;
      this.userService.datos = this.dat.response;

      this.cfor = this.userService.datos;
      this.dataSource = new MatTableDataSource(this.cfor);

      console.log(this.userService.datos);

      console.log('Finish this.http.get apiConsulta');
    });

    console.log('ConsultaComponent.constructor');
  }


  ngOnInit() {

  }

  botonCerrarSesion() {

    this.userService.cerrarSesion();
  }

  listaConsultaDatos() {
    this.userService.consultaDatos();
  }

}
