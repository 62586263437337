import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../Models/UserModel';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl;
  apiUrl2;
  apiConsulta;

  tokenUser;
  tokenAux: any = null;

  datos: any[] = [];
  dat;
  tusuario;
  i;

  searchText: string = '';

  constructor(private http: HttpClient, private router: Router) {

    this.apiUrl = environment.apiendpoint;
    this.apiUrl2 = environment.apiendpoint2;
    this.apiConsulta = environment.urlconsulta;

  }


    getUsers(user: User) {

      return this.http.get(this.apiUrl +
        'api/v2/seguridadinternet/login?suscripcion=copermatica&usuario=' + user.usuario + '&password=' + user.password);
    }


    adduser(user: User) {

      this.http.get(this.apiUrl +
        'api/v2/seguridadinternet/login?suscripcion=copermatica&usuario=' + user.usuario + '&password=' + user.password + '&sesion=false')
      .subscribe(resp => {
          this.tokenAux = resp;
          if (this.tokenAux.success) {
            localStorage.setItem('tokenUser', this.tokenAux.response);
            this.tokenUser = encodeURIComponent (this.tokenAux.response);
            this.router.navigate(['consulta']);
          }
          console.log(this.tokenAux);
          console.log(this.tokenUser);

          this.tusuario = user.usuario;


        });
    }


    cerrarSesion() {

      console.log(this.apiUrl2 + this.tokenUser);
      this.http.get(this.apiUrl2 + this.tokenUser)
               .subscribe(resp => console.log(resp));

      console.log('Hasta pronto');
      console.log(decodeURIComponent (this.tokenUser));
      localStorage.setItem('tokenUser', '');
      this.tokenUser = '';
      console.log('Queda el sihuiente token: ' + this.tokenUser);
      this.router.navigate(['home']);

    }

    consultaDatos() {
      let headersAux = new HttpHeaders();
      headersAux = headersAux.set('Content-Type', 'application/json; charset=utf-8');
      headersAux = headersAux.set('authtoken', localStorage.getItem('tokenUser'));

      this.http.get(this.apiConsulta, { headers: headersAux })
      .subscribe(resp => {
        this.dat = resp;
        this.datos = this.dat.response;

        console.log(this.datos);
      });

    }
}
