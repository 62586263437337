import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ConsultaComponent } from './components/consulta/consulta.component';
import { TableFilteringExampleComponent } from './components/table/table-filtering-example';


const ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'consulta', component: ConsultaComponent },
  { path: '**', component: HomeComponent },
  { path: 'table', component: TableFilteringExampleComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
