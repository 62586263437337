import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User } from '../../Models/UserModel';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title = 'LOGIN CONSULTA CONTRATOS';
  toke;
  miFormulario: FormGroup;
  flag = false;
  users: any[] = [];

  constructor(private userService: UserService, private fb: FormBuilder) {


  }

  ngOnInit() {

    this.miFormulario = this.fb.group({
      usuario: ['', Validators.required],
      password: ['', Validators.required]

    });
  }

  onSubmit(formValue: any) {

    const user = new User();

    user.usuario = formValue.usuario;
    user.password = formValue.password;

    this.userService.adduser(user);
    this.userService.getUsers(user)
    .subscribe((resp: any) => {
      this.users = resp;
    });
  }

  botonCerrarSesion() {

    this.userService.cerrarSesion();
  }


}
